import React, { useState, useEffect } from "react";
import styled from "styled-components";
import hadithIcon from "../images/hadithIcon.png";
import Http from "../helpers/Http";
import Template from "./Template";

const Service = () => {
  const [services, setServices] = useState([]);
  useEffect(() => {
    try {
      Http.get("content/type/1")
        .then((response) => {
          console.log("services: ", response.data.contents);
          setServices(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container>
      <Template data={services} image={hadithIcon} type="HADITH" />
    </Container>
  );
};

export default Service;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;
